<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <ayl-table :table="table">
          <div slot="ctrl-button">
           <el-button type="warning" @click="$router.push('/vehicle-management/add-device')">新增</el-button>
          </div>
        </ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        nav: [
          {name: '车辆管理', path: ''},
          {name: '设备管理', path: ''}
        ],
        dialogExport: false,
        checkList: [],
        checkIndex: [],
        btnLoading: false,
        table: {
          // api 查询当前列表页数据的全局方法
          api: vm.$api.ListDeviceInfo,
          // query 查询当前列表页数据的参数
          query: {
            deviceType: null,
            status: null,
            companyId: null,
            queryContent: null,
          },
          // 是否显示分页
          // hidePagination: true,
          // 设置表格高度
          // tableHeight: '250px',
          // 表格查询的条件组件
          searchData: [{
            type: 'select',
            title: '设备类型',
            model: 'deviceType',
            placeholder: '请选择',
            option: this.$enums.Operator.allList()
          }, {
            type: 'input',
            model: 'queryContent',
            placeholder: '设备号、设备厂家、安装车辆'
          }],
          columns: [
            {
              title: '设备号',
              width: '150px',
              showTooltip: true,
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.deviceInfoVOIOV.deviceName),
                ])
              }
            }, {
              title: '设备类型',
              width: '90px',
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.deviceInfoVOIOV.deviceType),
                ])
              }
            }, {
              title: '设备功能',
              width: '150px',
              showTooltip: true,
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.deviceInfoVOIOV.deviceFunction||'- -'),
                ])
              }
            }, {
              title: '安装车辆',
              width: '100px',
              showTooltip: true,
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.deviceInfoVOIOV.plateNumber|| '- -'),
                ])
              }
            }, {
              title: 'SIM卡号',
              width: '150px',
              showTooltip: true,
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.deviceInfoVOIOV.simCardNumber),
                ])
              }
            },
            // {
            //   title: '协议类型',
            //   width: '80px',
            //   render (h, ctx) {
            //     return h('span', [
            //       h('span', ctx.row.deviceInfoVOIOV.netProtocol),
            //     ])
            //   }
            // },
            {
              title: '安装情况',
              width: '80px',
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.deviceInfoVOIOV.install),
                ])
              }
            }, {
              title: '设备厂家/型号',
              showTooltip: true,
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.deviceInfoVOIOV.deviceCompany + '/' + ctx.row.deviceInfoVOIOV.deviceModel),
                ])
              }
            }, {
              title: '所属公司',
              showTooltip: true,
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.deviceInfoVOIOV.company),
                ])
              }
            }, {
              title: '操作',
              width: '120px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': true
                    },
                    on: {
                      click: vm.goEdit.bind(this, ctx.row)
                    }
                  }, '编辑'),
                  h('span', {
                    class: {
                      'table-delete': true
                    },
                    on: {
                      click: vm.delete.bind(this, ctx.row)
                    }
                  }, '删除')
                ])
              }
            }]
        }
      }
    },
    methods: {
      goEdit (value) {
        this.$router.push({
          path: '/vehicle-management/add-device',
          query: {
            deviceId: value.deviceInfoVOIOV.deviceId,
            type: 'edit'
          }
        })
      },
      async delete (val) {
        await this.$confirm(`您确认删除【${val.deviceInfoVOIOV.deviceName}】吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        await this.$api.DeleteDeviceInfo({
          deviceIds: [val.deviceInfoVOIOV.deviceId]
        })
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        await this.$search(this.table)
      }
    },
    beforeRouteEnter (to, from, next) {
      if(to.name=="Equipment-management"){
        next(async vm=>{
          await vm.$search(vm.table)
        });
      }else{  
        next();
      }
    },
    // async mounted () {
    // await this.$search(this.table)
    //   const companyList = await this.$api.SearchCompanyList({})
    //   this.table.searchData[2].option = companyList.map(e => {
    //     return {
    //       label: e.company,
    //       value: e.companyId
    //     }
    //   })
    // }
  }
</script>

<style lang='sass' scoped>
  /deep/ .el-checkbox
    min-width: 125px
</style>
